.botHeadingCard {
  padding: 24px 32px;
  border-radius: 10px;
  background: #f9f9ff;
  box-shadow: none;
  width: 100%;
}
.botHeading {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 700;
  color: #7a72e1;
}
.cell-padding {
  align-content: center;
}
.flex {
  display: flex;
  margin: 0px;
  align-content: center;
}
.enableChip {
  background: #63fd633d;
  color: #828282;
  height: 28px;
}
.disableChip {
  background: #ffff7d70;
  color: #828282;
  height: 28px;
}
.createSubHeading {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 33px;
  letter-spacing: -0.03em;
  text-align: center;
  background: linear-gradient(90deg, #2200ff 20.5%, #d230ff 100%);
  background-clip: inherit;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.uploadText {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 26.4px;
  letter-spacing: -0.03em;
  text-align: center;
  color: #232323;
}
.fileText {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: #4b4d4f;
}
.smallText {
  font-family: Poppins;
  font-size: 11px;
  font-weight: 300;
  line-height: 16.5px;
  text-align: left;
  color: #696969;
}
.trainingCard {
  border-radius: 24px;
  padding: 24px;
  justify-items: center;
  height: 132px;
}
.smallCardTitle {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.launchCard {
  border-radius: 8px;
  background: #fff;
  border: 0.5px solid #d9d9d9;
  padding: 16px;
}
.launchSubHeading {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 20.4px;
  letter-spacing: -0.03em;
  color: #6c6c6c;
}
.launchText {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: 20.4px;
  letter-spacing: -0.03em;
  color: #232323;
}
.chatCard {
  border-radius: 20px;
  padding: 30px 63px;
  background: #f9f9ff;
  /* height: 835px; */
  position: relative;
  box-shadow: 0px 4px 8px 3px #00000026;
}
.chatInterface {
  font-family: Poppins;
  font-size: 34px;
  font-weight: 500;
  color: #000000;
}
.chat {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 500;
  color: #000000;
}
.date {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
}
.searchText {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  color: #767676;
  cursor: pointer;
  padding: 5px;
}
.searchText:hover {
  padding: 5px;
  background: #7a72e14d;
  border-radius: 8px;
}
.resourceLink {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
}
.chatLogo {
  font-family: Poppins;
  font-size: 25px;
  font-weight: 600;
  color: #000000;
  text-align: left;
  padding-left: 10px;
  text-transform: capitalize;
}
.version {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  color: #919191;
  text-align: center;
  margin-top: -14px;
}
.exampleHead {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  color: #000000;
}
.exampleText {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  color: #919191;
  text-decoration: underline;
}
.newDialog {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
}
.slice {
  margin: 0px;
}
.popOver .MuiPopover-paper {
  width: 79px;
  border-radius: 8px;
  background: #f9f9ff;
}
.hoverPopover {
  font-size: 12px;
  font-weight: 275;
  color: #000;
  font-family: Poppins;
}

.hoverPopover:hover {
  background: #80808017;
}
.typing-animation {
  display: flex;
  gap: 5px;
}
.typing-animation span {
  display: block;
  width: 8px;
  height: 8px;
  background-color: #aaa;
  border-radius: 50%;
  animation: blink 1.2s infinite;
}
.typing-animation span:nth-child(2) {
  animation-delay: 0.2s;
}
.typing-animation span:nth-child(3) {
  animation-delay: 0.4s;
}
@keyframes blink {
  0%,
  80%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}
.chatInnerCard {
  width: 100%;
}
/* HTML: <div class="ribbon">Your text content</div> */
.ribbon {
  font-size: 16px;
  font-weight: 400;
  font-family: Poppins;
  color: #fff;
}
.ribbon {
  --r: 0.8em; /* control the cutout */
  padding-inline: 0.5em calc(var(--r) + 0.25em);
  line-height: 1.8;
  clip-path: polygon(
    100% 0,
    0 0,
    0 100%,
    100% 100%,
    100% calc(100% - 0.25em),
    calc(100% - var(--r)) 50%,
    100% 0.25em
  );
  background: #f92121;
  width: fit-content;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 27px;
  border-radius: 4px;
}
.selectedSearch {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
  padding: 5px;
  background: rgb(122, 114, 225);
  color: white;
  border-radius: 8px;
}
.markPara p:first-of-type {
  margin: 10px 0px;
}
.hoverClass svg {
  display: none;
}

.hoverClass:hover svg {
  display: inline;
}
