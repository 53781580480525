/* .placeholderOption {
  display: none;
} */
.placeholderOption {
  display: none;
}
.selectInput {
  height: 40px;
  border-radius: 4px;
  background: #fff;
  font-size: 14px;
}
/* .selectInput .MuiOutlinedInput-notchedOutline {
  border: none;
} */
