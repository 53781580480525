.assistantSubList span {
  font-size: 14px;
  /* white-space: nowrap; */
}
.settingFormLable {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  margin: 0;
}
.settingFormLable button.secondary {
  text-transform: lowercase;
}
.primaryLink {
  text-decoration: none;
  font-size: "0.875rem";
  color: var(--bot-color-progress-dark);
}
.primaryLink:hover {
  color: currentColor;
  text-decoration: underline;
}
.multiBgButton {
  padding: 0.125rem 05rem;
  color: var(--bot-color-light);
  border-radius: 9999px;
  width: 40px;
  background-image: linear-gradient(
    90deg,
    var(--bot-multi-bg-1) 17.9%,
    var(--bot-multi-bg-2) 50.56%,
    var(--bot-multi-bg-3) 117.61%
  );
}
.MuiToggleButton-root {
  border-radius: 6px;
  background: white;
  cursor: pointer;
  text-transform: capitalize;
  border: 1px solid #6a57c8;
  color: #5447a9;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  width: 100%;
}
.MuiToggleButton-root.Mui-selected {
  background: linear-gradient(90deg, #7971e0 0%, #c7c1f3 100%);
  color: white;
}
.userForm {
  max-width: 80%;
}

.userForm .fieldLabelType,
.starterForm .fieldLabelType {
  margin-top: 0.25rem;
  background: linear-gradient(90deg, #f9f9ff 0%, #f9f9ff 100%);
  padding: 1rem 0.5rem 0.875rem;
  width: 100%;
}
.btnFlexEnd button:first-child {
  margin-right: 0.5rem;
}

.formFieldsContainer {
  width: 100%;
}
.starterForm .formFieldsContainer {
  margin-top: 0.25rem;
  background: linear-gradient(90deg, #f9f9ff 0%, #f9f9ff 100%);
  padding: 1rem 0.5rem 0.875rem;
  margin-bottom: 1rem;
}
.formFieldsContainerBg {
  background: #f9f9ff;
  margin-bottom: 0.5rem;
  padding: 0.5rem 1rem;
  width: 100%;
}
.starterForm,
.emailSetup,
.usageSecurity,
.userForm {
  width: 80%;
}

.stickyButton1 {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
}
.starterForm form {
  width: 100%;
}
.createGuideHelper .MuiFormHelperText-root {
  text-align: right;
  color: var(--bot-text-color-light);
}

.subHeadingText {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
  color: #61646b;
}
.headingText {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.08399999886751175px;
}
.tb-0 .MuiDataGrid-row {
  border-radius: 0 !important;
}
