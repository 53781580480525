:root {
  --variant-outlinedColor:var(--bot-color-progress-dark);
  --variant-textColor:var( --bot-color-dark);
  --variant-outlinedColor:var( --bot-color-dark);
  --variant-outlinedBorder:rgba(25, 118, 210, 0.5);
  --variant-containedColor:#fff;
  --variant-containedBg:var(--bot-multi-bg-2);
  --bot-color-light: #fff;
  --bot-color-dark: #121212;
  --bot-color-info: #3498db;
  --bot-color-success: #07bc0c;
  --bot-color-warning: #f1c40f;
  --bot-color-error: #e74c3c;
  --bot-color-transparent: hsla(0, 0%, 100%, 0.7);
  --bot-icon-color-info: var(--bot-color-info);
  --bot-icon-color-success: var(--bot-color-success);
  --bot-icon-color-warning: var(--bot-color-warning);
  --bot-icon-color-error: var(--bot-color-error);
  --bot-toast-width: 320px;
  --bot-toast-background: #fff;
  --bot-toast-min-height: 64px;
  --bot-toast-max-height: 800px;
  --bot-font-family: sans-serif;
  --bot-z-index: 9999;
  --bot-text-color-light: #757575;
  --bot-border-color:#e5e7eb;
  --bot-text-color-dark: #fff;
  --bot-text-color-info: #fff;
  --bot-text-color-success: #fff;
  --bot-text-color-warning: #fff;
  --bot-text-color-error: #fff;
  --bot-spinner-color: #616161;
  --bot-text-color:#000;
  --bot-spinner-color-empty-area: #e0e0e0;
  --bot-color-progress-light: linear-gradient(
    90deg,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );
  --bot-color-progress-dark: #bb86fc;
  --bot-color-gray: #61646b;
  --bot-multi-bg-1: #ff76df;
  --bot-multi-bg-2: #6e56cf;
  --bot-multi-bg-3: #0091ff;
  --bot-color-progress-info: var(--bot-color-info);
  --bot-color-progress-success: var(--bot-color-success);
  --bot-color-progress-warning: var(--bot-color-warning);
  --bot-color-progress-error: var(--bot-color-error);
}
@import url("~@fontsource/poppins/index.css");
@import url("~@fontsource/poppins/100.css");
@import url("~@fontsource/poppins/200.css");
@import url("~@fontsource/poppins/300.css");
@import url("~@fontsource/poppins/500.css");
@import url("~@fontsource/poppins/600.css");
@import url("~@fontsource/poppins/700.css");
@import url("~@fontsource/poppins/800.css");
@import url("~@fontsource/poppins/900.css");
@import url("~@fontsource/space-grotesk/index.css");
@import url("~@fontsource/space-grotesk/300.css");
@import url("~@fontsource/space-grotesk/500.css");
@import url("~@fontsource/space-grotesk/600.css");
@import url("~@fontsource/space-grotesk/700.css");
@import url("~@fontsource/inter/index.css");
@import url("~@fontsource/inter/100.css");
@import url("~@fontsource/inter/200.css");
@import url("~@fontsource/inter/300.css");
@import url("~@fontsource/inter/500.css");
@import url("~@fontsource/inter/600.css");
@import url("~@fontsource/inter/700.css");
@import url("~@fontsource/inter/800.css");
@import url("~@fontsource/inter/900.css");

html,
body,
#root {
  margin: 0;
  height: 100vh;
  font-family: "Poppins";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c7c1f3;
}

.heading{
  font-weight: 600;
  font-size:14px;
}
.imgIcon{
  margin-right: 0.25rem;
}
