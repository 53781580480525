.tb-0 {
  border: none;
  font-family: "Poppins";
}
.MuiDataGrid-columnSeparator {
  display: none !important;
}
.MuiDataGrid-columnHeaders {
  border: none;
  background: #f9f9ff;
}
.tb-0 .MuiDataGrid-row--borderBottom {
  background: #f9f9ff !important;
}
.tb-0 .MuiDataGrid-columnHeaderTitle {
  font-family: "Poppins";
  font-style: normal;
  line-height: 20px;
  display: flex;
  letter-spacing: 1px;
  color: #4f4f4f;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  text-align: left;
}

.bold-text .MuiDataGrid-columnHeaderTitle {
  font-weight: 600;
}
.cell-padding {
  padding-top: 1.41vh !important;
  padding-bottom: 1.6vh !important;
}

.bold-text {
  background-color: #d3d3d330;
}
.bold-text .cell-padding .MuiDataGrid-cellContent {
  font-weight: 500;
}
.topAlign {
  display: flex;
  align-items: flex-start !important;
}

.mainPage {
  overflow-y: auto;
  height: 100vh;
  background: #f1f1f1;
  padding: 50px;
}
.MuiDataGrid-footerContainer {
  margin-top: 10px;
}

/* .MuiDataGrid-columnHeaders {
  padding: 0px 0px 0px 12px;
 
} */

.MuiDataGrid-row.MuiDataGrid-row--dynamicHeight {
  min-height: 45px !important;
}

.tb-0 .MuiDataGrid-cell {
  font-family: Poppins;
  font-style: normal;
  letter-spacing: 1px;
  color: #000;
  word-break: break-word;
  font-size: 14px;
  font-weight: 275;
  line-height: 20px;
  text-align: left;
}

.tb-0 .MuiDataGrid-columnHeader:focus,
.MuiDataGrid-cell:focus {
  outline: none !important;
  color: #1d2026;
}

.MuiTablePagination-toolbar {
  color: #1d2026;
}
.MuiDataGrid-root .MuiDataGrid-cell:focus-within,
.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within {
  outline: solid #ffffff 0px;
}
.MuiDataGrid-iconButtonContainer {
  visibility: visible;
}
.MuiDataGrid-sortIcon {
  opacity: inherit !important;
}
/* .MuiDataGrid-virtualScroller{
  overflow-x: hidden;
  overflow-y: scroll !important;
} */
.even-row {
  background: #edeefc;
}
.tb-0 .even-row:hover {
  background: #d8daf6;
}
.odd-row {
  background: #e6f1fd;
}
.tb-0 .odd-row:hover {
  background: #d6e6f8;
}
.tb-0 .MuiDataGrid-row {
  --rowBorderColor: none;
  border-radius: 16px;
  cursor: pointer;
}