.alertClass {
  border-radius: 5px;
}

.MuiAlert-message {
  white-space: nowrap;
}
.MuiSnackbar-root {
  max-width: 100%;
}
