.heading {
  color: #7a72e1;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
}
.secondaryText {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  color: #696969;
  margin-top: 22px;
}
.signIn {
  font-family: Poppins;
  font-size: 42px;
  font-weight: 500;
  text-align: left;
  color: #7a72e1;
  margin-bottom: 35px;
}
.Mui-required .MuiFormLabel-asterisk {
  color: red;
}
.MuiFormLabel-root.MuiInputLabel-root {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 300;
  line-height: 19.5px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: rgba(105, 105, 105, 1);
}
.MuiFormHelperText-root {
  margin-left: 0px;
  color: red;
}
.mt-100 {
  margin-top: 100px;
}
.otpHead {
  font-family: Poppins;
  font-size: 28px;
  font-weight: 600;
  line-height: 42px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #4d4d4d;
}
.otpSubHead {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #696969;
  margin-top: 10px;
}
.email {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
  margin-top: 20px;
}
.otpText {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: #696969;
  margin-bottom: 50px;
}
.resend {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  text-align: left;
  color: #2200ff;
  margin-top: 16px;
  cursor: pointer;
}
.time {
  color: #6d8196;
}
.disableResend {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  text-align: left;
  margin-top: 16px;
  cursor: not-allowed;
}
.MuiOtpInput-Box .MuiOutlinedInput-notchedOutline {
  border: none;
  border-bottom: 1px solid #696969;
}
