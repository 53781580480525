.primary {
  border-radius: 6px;
  background: linear-gradient(90deg, #7971e0 0%, #c7c1f3 100%);
  /* box-shadow: 0px 7px 13px 0px #00000040; */
  text-transform: capitalize;
  color: white;
  cursor: pointer;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
}
.secondary {
  border-radius: 6px;
  background: white;
  cursor: pointer;
  text-transform: capitalize;
  border: 1px solid #6a57c8;
  color: #5447a9;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
}

/* .secondary::before {
  content: "";
  position: absolute;
  left: 1px;
  right: 1px;
  top: 1px;
  bottom: 1px;
  border-radius: 80px;
  background-color: white;
  z-index: -1;
  transition: 200ms;
}
.secondary::after {
  content: attr(data-text);
  font-size: 18px;
  color: #7a72e1;
  font-weight: 300;
}
.secondary:hover::before {
  opacity: 0%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.secondary:hover::after {
  color: white;
} */
.tertiary {
  border: 1px solid #d1d5db;
  border-radius: 6px;
  text-transform: capitalize;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  color: #5447a9;
  height: 34px;
  padding: 9px;
}
