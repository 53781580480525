.outlet {
  padding: 15px 62px 142px 62px;
}
.notSelected {
  margin: 0 1rem;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  color: #4f4f4f;
}
.selected {
  color: #7a72e1;
  margin: 0 1rem;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 700;
}
.bookmark {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  color: #00000066;
}
.logo {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.20800000429153442px;
  color: #4f4f4f;
  align-content: center;
}
.logoContainer {
  display: grid;
  padding: 20px 12px 20px 20px;
  border-bottom: 1px solid #f8f8f8;
}
.logoSub {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  color: #4f4f4f;
}
.arrowImg {
  width: 14px;
  height: 14px;
  vertical-align: middle;
  margin-left: 20px;
}
.sidebarItemsContainer li {
  padding: 8px;
  padding-bottom: 12px;
}
.scrollSidebar {
  padding: 16px;
}
.listTextCss span {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  /* color: #4f4f4f; */
}
.logoutContainer {
  position: absolute;
  bottom: 0px;
  padding-bottom: 20px;
}
.heading {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.20800000429153442px;
  color: #000;
}
.sideBarMain {
  height: 100%;
}
.testDialog .MuiDrawer-paper {
  min-width: 420px;
}
.typing-animation {
  display: flex;
  gap: 5px;
}
.typing-animation span {
  display: block;
  width: 8px;
  height: 8px;
  background-color: #aaa;
  border-radius: 50%;
  animation: blink 1.2s infinite;
}
.typing-animation span:nth-child(2) {
  animation-delay: 0.2s;
}
.typing-animation span:nth-child(3) {
  animation-delay: 0.4s;
}
@keyframes blink {
  0%,
  80%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}
.messagePara p {
  font-size: 14px;
  font-weight: 400;
}
