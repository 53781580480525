.search {
  border-radius: 8px;
  background: #fff;
  border: 1px solid rgb(229 231 235);
}

.search .MuiInputBase-root {
  border-radius: 8px;
}
.searchIcon {
  font-size: 24px;
  margin-left: auto;
  color: #ffffff;
}
.searchIconBtn {
  height: 100%;
}
.searchIconBtn:disabled {
  cursor: not-allowed;
}
.searchIconBtn:hover {
}

.searchTextStyle .MuiInputBase-input {
  font-size: 14px;
  line-height: 24px;
  margin-left: 10px;
  font-weight: 400;
  display: flex;
  align-items: center;
  color: #4f4f4f;
  font-family: Poppins;
}
